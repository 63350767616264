import React from "react";
import { Layout } from "@components";
import { Briefly, QuickActions, News } from "@page-contents/index";

// test
export default props => (
	<Layout {...props}>
		<Briefly />
		<QuickActions />
		<News />
	</Layout>
);
