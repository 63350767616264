import "./quick-actions.scss";
import React from "react";
import Link from "gatsby-link";

const content = [
	{ name: "Тарифы и стоимость", to: "/services/tariffs" },
	{ name: "Где пополнить", to: "/where-to-refill/" },
	{ name: "Где приобрести", to: "/where-to-buy/" },
	{ name: "Как пользоваться", to: "/instructions/" }
];

const QuickActions = () => (
	<section className="quick-actions">
		<div className="grid-container">
			<div className="row">
				{content.map((item, index) => (
					<div className="col-xs-12 col-sm-6 col-md-3" key={index}>
						<Link to={item.to || "/"} className="quick-actions__button-item">
							<span className="quick-actions__counter-ball">{index + 1}</span>
							{item.name}
						</Link>
					</div>
				))}
			</div>
		</div>
	</section>
);

export default QuickActions;
