import "./news-list.scss";
import React from "react";
import NewsListItem from "../../page-contents/index/news-list-item";
import { graphql, StaticQuery } from "gatsby";
import Link from "gatsby-link";

const Header = ({ data = {} }) => {
	const { allMarkdownRemark: { edges = {}, totalCount } = {} } = data;
	return (
		<section className="grid-container news-list">
			<div className="row">
				<div className="col-xs-12 col-sm-12 col-md-12 news-list__top">
					<h2 className="news-list__header">Новости</h2>
					{totalCount > 4 && (
						<Link className="link-l news-list__all" to="/about/news">
							Все новости
						</Link>
					)}
				</div>
			</div>
			<div className="row">
				{edges &&
					edges.map(({ node }, index) => {
						const {
							frontmatter: { image, title, path, publishedAt } = {}
						} = node;
						return (
							<div
								className="col-xs-12 col-sm-6 col-md-3 news-list__item-wrapper"
								key={index}
							>
								<NewsListItem
									pic={image}
									text={title}
									path={path}
									publishedAt={publishedAt}
									alt="Изображение"
									className="news-list__item"
								/>
							</div>
						);
					})}
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				allMarkdownRemark(
					filter: { frontmatter: { path: { regex: "/news/" } } }
					sort: { fields: frontmatter___publishedAt, order: DESC }
					limit: 4
				) {
					edges {
						node {
							frontmatter {
								path
								image
								title
								publishedAt
								content
							}
						}
					}
					totalCount
				}
			}
		`}
		render={data => <Header data={data} {...props} />}
	/>
);
